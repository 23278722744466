import { Col, Row, Table } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import styles from "./ProfileSettings.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import UploadDocuments from "./UploadDocuments";
import { BankInfo, InfoUser } from "../../../stores/UserStore";
import CustomInput from "../../../components/CustomInput";
import Button from "../../../components/Button";
import ToggleSwitch from "../../../components/ToggleSwitch";
import Logistics from "./Logistics";
import CategoriesTree from "./CategoriesTree";
import AgreementUsage from "./Agreement_Usage";
import * as yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import ChooseFilters from "./ChooseFilters";

const registerSchema = yup.object().shape({
  firstName: yup.string().required(" "),
  lastName: yup.string().required(" "),
  phoneNumber: yup
    .string()
    .matches(/^\+?[1-9]\d{1,14}$/, "Номер телефона недействителен")
    .required(" "),
  tin: yup
    .string()
    .required(" ")
    .matches(/^[0-9]+$/, "поле должно содержать только цифры"),
  msrnie: yup
    .string()
    .required(" ")
    .matches(/^[0-9]+$/, "поле должно содержать только цифры"),
  passportSeries: yup.string().required(" "),
  passportNumber: yup.string().required(" "),
  issuedBy: yup.string().required(" "),
  passportIssueDate: yup.string().required(" "),
  departmentCode: yup.string().required(" "),
  registrationAddress: yup.string().required(" "),
  actualAddress: yup.string().required(" "),
});

const ProfileSettings: React.FC = observer(() => {
  const {
    userStore: {
      info,
      userInfo,
      getBankInfo,
      changeBankInfo,
      changeUserInfo,
      setInfo,
      getTermsOfUsage,
    },
    loginStore: { updateEnableTwoFactor },
    emailStore: { getUserSubscription, updateUserSubscription },
  } = useContext(RootStoreContext);

  const [bankInfo, setBankInfo] = useState<BankInfo | null>();
  const [changeLoad, setChangeLoad] = useState<boolean>(false);

  const [loadTwoFactor, setLoadTwoFactor] = useState<boolean>(false);
  const [userSubscribe, setUserSubscribe] = useState<boolean>(false);
  const [loadUserSubscribe, setLoadUserSubscribe] = useState<boolean>(false);

  const { values, handleBlur, handleChange, touched, handleSubmit, errors } =
    useFormik({
      initialValues: {
        id: userInfo?.id ? userInfo?.id : "",
        firstName: userInfo?.firstName ? userInfo?.firstName : "",
        lastName: userInfo?.lastName ? userInfo?.lastName : "",
        phoneNumber: userInfo?.phoneNumber ? userInfo?.phoneNumber : "",
        tin: userInfo?.tin ? userInfo?.tin : "",
        msrnie: userInfo?.msrnie ? userInfo?.msrnie : "",
        passportSeries: userInfo?.passportSeries
          ? userInfo?.passportSeries
          : "",
        passportNumber: userInfo?.passportNumber
          ? userInfo?.passportNumber
          : "",
        issuedBy: userInfo?.issuedBy ? userInfo?.issuedBy : "",
        passportIssueDate: userInfo?.passportIssueDate
          ? userInfo?.passportIssueDate
          : "",
        departmentCode: userInfo?.departmentCode
          ? userInfo?.departmentCode
          : "",
        registrationAddress: userInfo?.registrationAddress
          ? userInfo?.registrationAddress
          : "",
        actualAddress: userInfo?.actualAddress ? userInfo?.actualAddress : "",
      },
      validationSchema: registerSchema,
      onSubmit: async () => {
        setChangeLoad(true);
        changeUserInfo(
          values.id,
          values.firstName,
          values.lastName,
          values.phoneNumber,
          values.tin,
          values.msrnie,
          values.passportSeries,
          values.passportNumber,
          values.issuedBy,
          values.passportIssueDate,
          values.departmentCode,
          values.registrationAddress,
          values.actualAddress
        ).then(() => {
          setChangeLoad(false);
        });
      },
    });

  useEffect(() => {
    getTermsOfUsage();
    if (info?.id) {
      getBankInfo(info.id).then((bankInfo) => {
        setBankInfo(bankInfo);
      });
      getUserSubscription(info.id).then((i) => {
        setUserSubscribe(i);
      });
    }
  }, [getBankInfo, info?.id, getTermsOfUsage, getUserSubscription]);

  return (
    <div className={styles.root}>
      <p className={styles.title}>Настройки профиля</p>
      <div className={styles.wrapper}>
        <Row>
          <Col
            xl={info?.roles.includes("Seller") ? 4 : 7}
            lg={6}
            md={12}
            sm={12}
            xs={12}
          >
            <Table striped hover responsive className={styles.bankInfoTable}>
              <tbody>
                <tr>
                  <td colSpan={2} style={{ textAlign: "center" }}>
                    <b>Личные данные</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Имя</b>
                  </td>
                  <td>
                    <CustomInput
                      value={values.firstName}
                      error={touched.firstName ? errors.firstName : ""}
                      name="firstName"
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Фамилия</b>
                  </td>
                  <td>
                    <CustomInput
                      value={values.lastName}
                      error={touched.lastName ? errors.lastName : ""}
                      name="lastName"
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Почта</b>
                  </td>
                  <td> {userInfo?.email}</td>
                </tr>
                <tr>
                  <td>
                    <b>Номер телефона</b>
                  </td>
                  <td>
                    <CustomInput
                      value={values.phoneNumber}
                      error={touched.phoneNumber ? errors.phoneNumber : ""}
                      name="phoneNumber"
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Адрес регистрации</b>
                  </td>
                  <td>
                    <CustomInput
                      value={values.registrationAddress}
                      error={
                        touched.registrationAddress
                          ? errors.registrationAddress
                          : ""
                      }
                      name="registrationAddress"
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>Фактический адрес</b>
                  </td>
                  <td>
                    <CustomInput
                      value={values.actualAddress}
                      error={touched.actualAddress ? errors.actualAddress : ""}
                      name="actualAddress"
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b> Роль</b>
                  </td>
                  <td>
                    {info?.roles.includes("WarehouseOwner")
                      ? "Владелец склада"
                      : info?.roles.includes("Seller")
                      ? "Продавец"
                      : "Super admin"}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>ИНН</b>
                  </td>
                  <td>
                    <CustomInput
                      value={values.tin}
                      error={touched.tin ? errors.tin : ""}
                      name="tin"
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>ОГРНИП</b>
                  </td>
                  <td>
                    <CustomInput
                      value={values.msrnie}
                      error={touched.msrnie ? errors.msrnie : ""}
                      name="msrnie"
                      onChange={(e) => handleChange(e)}
                      onBlur={handleBlur}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ textAlign: "center" }}>
                    <div
                      className={
                        changeLoad
                          ? styles.changeButtonLoad
                          : styles.changeButton
                      }
                    >
                      <Button
                        onClick={() => {
                          handleSubmit();
                        }}
                        disabled={changeLoad}
                      >
                        Изменить данные
                      </Button>
                    </div>
                  </td>
                </tr>
                {info ? (
                  <tr>
                    <td>
                      <b>Двухфакторная аутентификация</b>
                    </td>
                    <td className={styles.toggleStyle}>
                      <ToggleSwitch
                        checked={info.twoFactorEnabled}
                        cursor={loadTwoFactor ? "progress" : "pointer"}
                        onChange={() => {
                          setLoadTwoFactor(true);
                          updateEnableTwoFactor(
                            info?.id,
                            !info.twoFactorEnabled
                          ).then((i) => {
                            if (i) {
                              setInfo({
                                ...info,
                                twoFactorEnabled: !info?.twoFactorEnabled,
                              });
                            }
                            setLoadTwoFactor(false);
                          });
                        }}
                        name="import"
                        leftText="Отключить"
                        rightText="Подключить"
                        icons={false}
                      />
                    </td>
                  </tr>
                ) : (
                  ""
                )}
                {info ? (
                  <tr>
                    <td>
                      <b>Подписка на рассылку</b>
                    </td>
                    <td className={styles.toggleStyle}>
                      <ToggleSwitch
                        checked={userSubscribe}
                        cursor={loadUserSubscribe ? "progress" : "pointer"}
                        onChange={() => {
                          setLoadUserSubscribe(true);
                          updateUserSubscription(!userSubscribe).then((i) => {
                            if (i) {
                              setUserSubscribe(!userSubscribe);
                            }
                            setLoadUserSubscribe(false);
                          });
                        }}
                        name="import"
                        leftText="Отключить"
                        rightText="Подключить"
                        icons={false}
                      />
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </Table>
          </Col>
          {bankInfo ? (
            <Col
              xl={info?.roles.includes("Seller") ? 4 : 5}
              lg={6}
              md={12}
              sm={12}
              xs={12}
            >
              <Table striped hover responsive className={styles.bankInfoTable}>
                <tbody>
                  <tr>
                    <td colSpan={2} style={{ textAlign: "center" }}>
                      <b>Паспортные данные</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Серия</b>
                    </td>
                    <td>
                      <CustomInput
                        value={values.passportSeries}
                        error={
                          touched.passportSeries ? errors.passportSeries : ""
                        }
                        name="passportSeries"
                        onChange={(e) => handleChange(e)}
                        onBlur={handleBlur}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Номер</b>
                    </td>
                    <td>
                      <CustomInput
                        value={values.passportNumber}
                        error={
                          touched.passportNumber ? errors.passportNumber : ""
                        }
                        name="passportNumber"
                        onChange={(e) => handleChange(e)}
                        onBlur={handleBlur}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Кем выдан</b>
                    </td>
                    <td>
                      <CustomInput
                        value={values.issuedBy}
                        error={touched.issuedBy ? errors.issuedBy : ""}
                        name="issuedBy"
                        onChange={(e) => handleChange(e)}
                        onBlur={handleBlur}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Дата выдачи</b>
                    </td>
                    <td>
                      <CustomInput
                        value={moment(values.passportIssueDate).format(
                          "YYYY-MM-DD"
                        )}
                        error={
                          touched.passportIssueDate
                            ? errors.passportIssueDate
                            : ""
                        }
                        name="passportIssueDate"
                        onChange={(e) => handleChange(e)}
                        onBlur={handleBlur}
                        type="date"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Код подразделения</b>
                    </td>
                    <td>
                      <CustomInput
                        value={values.departmentCode}
                        error={
                          touched.departmentCode ? errors.departmentCode : ""
                        }
                        name="departmentCode"
                        onChange={(e) => handleChange(e)}
                        onBlur={handleBlur}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ textAlign: "center" }}>
                      <div
                        className={
                          changeLoad
                            ? styles.changeButtonLoad
                            : styles.changeButton
                        }
                      >
                        <Button
                          onClick={() => {
                            handleSubmit();
                          }}
                          disabled={changeLoad}
                        >
                          Изменить данные
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>

              <Table striped hover responsive className={styles.bankInfoTable}>
                <tbody>
                  <tr>
                    <td colSpan={2} style={{ textAlign: "center" }}>
                      <b>Банковские данные</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Наименование банка</b>
                    </td>
                    <td>
                      <CustomInput
                        value={bankInfo?.bankName}
                        name={"bankName"}
                        onChange={(e) =>
                          setBankInfo({ ...bankInfo, bankName: e.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>БИК</b>
                    </td>
                    <td>
                      <CustomInput
                        value={bankInfo?.bik}
                        name={"bik"}
                        onChange={(e) =>
                          setBankInfo({ ...bankInfo, bik: e.target.value })
                        }
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Корреспондентский счет</b>
                    </td>
                    <td>
                      <CustomInput
                        value={bankInfo?.correspAccount}
                        name={"correspAccount"}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            correspAccount: e.target.value,
                          })
                        }
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Номер счета в банке</b>
                    </td>
                    <td>
                      <CustomInput
                        value={bankInfo?.personalAccount}
                        name={"personalAccount"}
                        onChange={(e) =>
                          setBankInfo({
                            ...bankInfo,
                            personalAccount: e.target.value,
                          })
                        }
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ textAlign: "center" }}>
                      <div
                        className={
                          changeLoad
                            ? styles.changeButtonLoad
                            : styles.changeButton
                        }
                      >
                        <Button
                          onClick={() => {
                            setChangeLoad(true);
                            changeBankInfo(bankInfo).then(() => {
                              setChangeLoad(false);
                            });
                          }}
                        >
                          Изменить данные
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          ) : (
            ""
          )}
          {info?.roles.includes("WarehouseOwner") ? <Logistics /> : ""}
          {info?.roles.includes("SuperAdmin") ? <AgreementUsage /> : ""}
          <UploadDocuments />
        </Row>
      </div>
      {info?.roles.includes("WarehouseOwner") ? <CategoriesTree /> : ""}
      {info?.roles.includes("WarehouseOwner") ? <ChooseFilters /> : ""}
    </div>
  );
});
export default ProfileSettings;
