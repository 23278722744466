import CustomSelect, { SelectValue } from "components/CustomSelect";
import classNames from "classnames";
import { components } from "react-select";
import React, { useContext, useEffect, useState } from "react";
import Pagination from "components/Pagination/Pagination";
import styles from "./Cart.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";

import Modal from "components/Modal";
import CartPopup from "./CartPopup";
import { useSearchParams } from "react-router-dom";
import Icon from "components/Icon";
import CartTable from "./CartTable";
import CartFilters from "./CartFilters";
import CartShipmentActs from "./CartShipmentActs";
import CartAssemblyTasks from "./CartAssemblyTasks";
import { MarketPlaces } from "stores/MarketPlaceStore";
import { Seller } from "stores/OrderStore";
import { WarehouseFilterI } from "stores/WarehouseStore";
import moment from "moment";

const Cart: React.FC = observer(() => {
  const {
    orderStore: { carts, getOrders, getOrderStatuses, orderStatuses, setCart },
    userStore: { info },
  } = useContext(RootStoreContext);

  const [loading, setLoading] = useState<boolean>(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const newParams = new URLSearchParams(searchParams);

  const [marketplaces, setMarketplaces] = useState<MarketPlaces[]>([]);
  const [sellers, setSellers] = useState<Seller[]>([]);
  const [warehouses, setWarehouses] = useState<WarehouseFilterI[]>([]);

  const marketplace = searchParams.get("marketplace");
  const seller =
    info?.roles.includes("SuperAdmin") || info?.roles.includes("WarehouseOwner")
      ? searchParams.get("seller")
      : info?.id;
  const pageSize = searchParams.get("pageSize");
  const pageNumber = searchParams.get("pageNumber");
  const sortingOrder = searchParams.get("sortingOrder");
  const status = searchParams.get("status");
  const searchText = searchParams.get("search");
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const mpWarehouseID = searchParams.get("mpWarehouseID");

  const filters = [
    info?.roles.includes("SuperAdmin")
      ? {
          label: "seller",
          value: sellers.filter((i) => i.id === seller)[0]
            ? sellers.filter((i) => i.id === seller)[0].sellerName
            : null,
        }
      : {},
    {
      label: "marketplace",
      value: marketplaces.filter((i) => i.id === Number(marketplace))[0]
        ? marketplaces.filter((i) => i.id === Number(marketplace))[0].name
        : null,
    },
    {
      label: "status",
      value:
        orderStatuses?.filter((i) => i.id === Number(status))[0] &&
        status !== null
          ? orderStatuses?.filter((i) => i.id === Number(status))[0].title
          : null,
    },
    {
      label: "mpWarehouseID",
      value: warehouses.filter((i) => i.id === Number(mpWarehouseID))[0]
        ? warehouses.filter((i) => i.id === Number(mpWarehouseID))[0].name
        : null,
    },
    { label: "search", value: searchText },
  ];

  const [orderId, setOrderId] = useState<any>(null);

  useEffect(() => {
    setLoading(true);
    getOrderStatuses();
    getOrders({
      ...(pageSize && { pageSize: Number(pageSize) }),
      ...(marketplace && { marketPlaceID: Number(marketplace) }),
      ...(pageNumber && { pageNumber: Number(pageNumber) }),
      ...(sortingOrder && { sortingOrder: Number(sortingOrder) }),
      ...(seller && { sellerID: seller }),
      ...(status && { orderStatus: Number(status) }),
      ...(searchText && { searchText: searchText }),
      ...(startDate && { shipmentDateFrom: startDate }),
      ...(endDate && { shipmentDateTo: endDate === "null" ? null : endDate }),
      ...(mpWarehouseID && { mpWarehouseID: Number(mpWarehouseID) }),
    }).then((data) => {
      if (data) {
        setMarketplaces(data.marketPlaces);
        setSellers(data.sellers);
        setWarehouses(data.warehouses);
        if (endDate === "null" && data.dateTo) {
          setSearchParams((params) => {
            params.set("endDate", data.dateTo.split("T")[0]);
            return params;
          });
        } else {
          setSearchParams((params) => {
            params.set("endDate", moment().format("YYYY-MM-DD"));
            return params;
          });
        }
      }

      setLoading(false);
    });
  }, [
    setSearchParams,
    getOrders,
    pageSize,
    marketplace,
    pageNumber,
    sortingOrder,
    seller,
    status,
    searchText,
    startDate,
    endDate,
    mpWarehouseID,
    getOrderStatuses,
  ]);

  const handlePages = (updatePage: number) => {
    newParams.set("pageNumber", `${updatePage}`);
    setSearchParams(newParams);
  };

  const handleOrderBy = (updatePage: number) => {
    newParams.set("sortingOrder", `${updatePage}`);
    setSearchParams(newParams);
  };

  const handleFilters = (e: SelectValue, name: string) => {
    newParams.set(name, e.value);
    setSearchParams(newParams);
  };

  const MenuList: React.FC<any> = (props) => {
    const { children, ...otherProps } = props;
    return (
      <components.MenuList {...otherProps}>{children}</components.MenuList>
    );
  };
  return (
    <div className={styles.root}>
      <p className={styles.title}>Заказы</p>
      <div className={styles.wrapper}>
        <div className={styles.filters}>
          {!loading ? (
            <CartFilters
              marketplace={marketplace}
              marketPlaces={marketplaces}
              warehouses={warehouses}
              mpWarehouseID={mpWarehouseID}
              endDate={endDate}
              searchText={searchText}
              startDate={startDate}
              seller={seller}
              sellers={sellers}
              status={status}
            />
          ) : (
            ""
          )}
          <CartShipmentActs />
          <CartAssemblyTasks />
        </div>
        {carts ? (
          <div className={styles.tags}>
            {filters.map(
              (item, index) =>
                item.value && (
                  <div className={styles.tag} key={index}>
                    <div className={styles.label}>{item.value}</div>
                    <Icon
                      onClick={() => {
                        newParams.delete(item.label);
                        setSearchParams(newParams);
                      }}
                      variant="cross"
                      size="small"
                    />
                  </div>
                )
            )}
            {filters.filter((i) => i.value).length ? (
              <a
                className={styles.reset}
                onClick={() => {
                  setSearchParams({
                    ...(startDate && { startDate: startDate }),
                    ...(endDate && { endDate: endDate }),
                    pageNumber: "1",
                    pageSize: "20",
                  });
                }}
              >
                Сбросить все фильтры
              </a>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        <CartTable
          loading={loading}
          info={info}
          sortingOrder={sortingOrder}
          handleOrderBy={handleOrderBy}
          carts={carts}
          marketplace={marketplace}
        />
        {carts && carts.pageCount > 1 && (
          <div className={classNames(styles.pagination)}>
            <CustomSelect
              components={{ MenuList }}
              className={styles.small}
              name="pageSize"
              placeholder="qty"
              onChange={(e) => {
                handleFilters(e, "pageSize");
              }}
              value={pageSize}
              list={[
                { value: "20", label: "20" },
                { value: "40", label: "40" },
                { value: "60", label: "60" },
              ]}
            />
            <Pagination
              page={Number(pageNumber)}
              totalPages={carts.pageCount}
              handlePagination={handlePages}
            />
          </div>
        )}
        {orderId && (
          <Modal
            className={styles.cartModal}
            children={<CartPopup id={orderId} />}
            isOpen={orderId}
            onClose={() => {
              setOrderId(null);
              setCart(null);
            }}
          />
        )}
      </div>
    </div>
  );
});
export default Cart;
