import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Invoices.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import BeatLoader from "react-spinners/BeatLoader";
import moment from "moment";
import { ReturnInvoiceTablePart, TorgTablePart } from "stores/OrderStore";
import Modal from "components/Modal";
import TorgTablePartPopup from "./TorgTablePart";
import { useSearchParams } from "react-router-dom";
import YandexLogo from "assets/icons/yandex.png";
import OzonLogo from "assets/icons/ozon.png";
import WildberriesLogo from "assets/icons/wildberries.png";
import ReturnInvoiceTablePartPopup from "./ReturnInvoiceTablePart";
import toast from "react-hot-toast";
import { HiDocumentDownload } from "react-icons/hi";

const ReturnInvoices = observer(() => {
  const {
    orderStore: {
      getReturnInvoicesList,
      returnInvoicesList,
      downloadReturnInvoice,
    },
    userStore: { info },
  } = useContext(RootStoreContext);

  const [returnInvoiceTablePart, setReturnInvoiceTablePart] =
    useState<ReturnInvoiceTablePart[]>();
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalVat, setTotalVat] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(true);
  const [labelLoading, setLabelLoading] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<string>(
    moment().subtract(1, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(moment().format("YYYY-MM-DD"));

  const [searchParams] = useSearchParams();

  const tab = searchParams.get("tab");

  useEffect(() => {
    setLoading(true);
    if (info && tab === "returnInvoices") {
      getReturnInvoicesList(
        info?.roles.includes("Seller") &&
          !info?.roles.includes("WarehouseOwner")
          ? info?.id
          : null,
        info?.roles.includes("WarehouseOwner") ? info?.id : null,
        startDate,
        endDate
      ).then(() => {
        setLoading(false);
      });
    }
  }, [getReturnInvoicesList, startDate, endDate, info, tab]);

  return (
    <>
      <div className={styles.dates}>
        <div>
          <div>Период изменений</div>

          <div>
            от:
            {
              <input
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            }
          </div>

          <div>
            до:
            {
              <input
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            }
          </div>
        </div>
      </div>
      {loading ? (
        <div style={{ width: "fit-content", margin: "auto" }}>
          <BeatLoader color={"#f49100"} loading={loading} size={50} />
        </div>
      ) : (
        <Table striped hover>
          <thead>
            <tr>
              <th>Номер документа</th>
              <th>Продавец</th>
              <th>Покупатель</th>
              <th>Дата создания</th>
              <th style={{ textAlign: "center" }}>МП</th>
              <th>Сумма</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {returnInvoicesList &&
              returnInvoicesList.map((item, index) => (
                <tr key={index}>
                  <td
                    className={styles.cardTitle}
                    onClick={() => {
                      setReturnInvoiceTablePart(
                        item.returnInvoiceTablePartModel
                      );
                      setTotalPrice(item.totalPrice);
                      setTotalVat(item.totalPrice);
                    }}
                  >
                    {item.returnInvoiceNm}
                  </td>
                  <td>{item.seller}</td>
                  <td>{item.buyer}</td>
                  <td>{moment(item.creationDate).format("DD.MM.YYYY")}</td>
                  <td className={styles.MPLogos}>
                    {item.marketPlaceID === 2 ? (
                      <img src={YandexLogo} alt="yandex logo" />
                    ) : item.marketPlaceID === 1 ? (
                      <img src={OzonLogo} alt="ozon logo" />
                    ) : (
                      <img src={WildberriesLogo} alt="wildberries logo" />
                    )}
                  </td>
                  <td>{item.totalPrice} ₽</td>
                  <td className={styles.packageLabel}>
                    <div
                      style={{
                        cursor: labelLoading ? "progress" : "pointer",
                      }}
                      onClick={() => {
                        setLabelLoading(true);

                        downloadReturnInvoice(item.returnInvoiceNm).then(
                          (i) => {
                            if (!i) {
                              toast.error("Этикетка еще не готова.");
                            }
                            setLabelLoading(false);
                          }
                        );
                      }}
                      className={styles.packageLabelA}
                    >
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Скачать</Tooltip>}
                      >
                        <div>
                          <HiDocumentDownload size={38} />
                        </div>
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
      {returnInvoiceTablePart?.length !== undefined &&
        returnInvoiceTablePart.length > 0 && (
          <Modal
            className={styles.cartModal}
            children={
              <ReturnInvoiceTablePartPopup
                returnInvoiceTablePart={returnInvoiceTablePart}
                totalPrice={totalPrice}
                totalVat={totalVat}
              />
            }
            isOpen={!!returnInvoiceTablePart.length}
            onClose={() => {
              setReturnInvoiceTablePart([]);
            }}
          />
        )}
    </>
  );
});
export default ReturnInvoices;
