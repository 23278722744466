import { useContext, useEffect, useState } from "react";
import s from "./questions.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "stores/RootStore";
import { QuestionsChat } from "scenes/Dashboard/Home/Home";
interface Props {
  productId: string;
}
const Questions: React.FC<Props> = observer(({ productId }) => {
  const {
    warehouseStore: { getProductChat },
  } = useContext(RootStoreContext);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [questions, setQuestions] = useState<QuestionsChat[]>([]);
  const handleClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    getProductChat(productId).then((prod) => {
      setQuestions(prod);
    });
  }, [getProductChat, productId]);
  return (
    <div className={s.questionsContainerSection}>
      <button className={s.questionBtn} onClick={handleClick}>
        Вопросы
      </button>
      {isOpen && (
        <div className={s.questionsContainer}>
          <div className={s.questionContent}>
            {!questions || questions.length === 0 ? (
              <span>Нет вопросов</span>
            ) : (
              ""
            )}
            {questions &&
              questions.map((item: any, index: number): any => {
                return (
                  <>
                    <p className={s.UserProfile}>
                      <span>
                        <svg
                          fill="#000000"
                          width="20px"
                          height="20px"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <title />

                          <g data-name="Layer 2" id="Layer_2">
                            <path d="M16,14a6,6,0,1,1,6-6A6,6,0,0,1,16,14ZM16,4a4,4,0,1,0,4,4A4,4,0,0,0,16,4Z" />

                            <path d="M24,30H8a2,2,0,0,1-2-2V22a7,7,0,0,1,7-7h6a7,7,0,0,1,7,7v6A2,2,0,0,1,24,30ZM13,17a5,5,0,0,0-5,5v6H24V22a5,5,0,0,0-5-5Z" />
                          </g>
                        </svg>
                      </span>
                      <span className={s.AnswerTitle}>Продавец</span>
                    </p>
                    <p className={s.questionText} key={index}>
                      {item.question}
                    </p>

                    {item.answer ? (
                      <div className={s.answerContainer}>
                        <p className={s.answerTitle}>Oтвет на вопрос</p>
                        <div className={s.answerText}>
                          <p className={s.answerText}>{item.answer}</p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
});

export default Questions;
