import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Invoices.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../../stores/RootStore";
import BeatLoader from "react-spinners/BeatLoader";
import moment from "moment";
import Button from "components/Button";
import { HiDocumentDownload } from "react-icons/hi";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";

const PaymentBills: React.FC = observer(() => {
  const {
    orderStore: {
      getPaymentBillsList,
      paymentBills,
      signPaymentBill,
      downloadBill,
    },
    userStore: { info },
  } = useContext(RootStoreContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [labelLoading, setLabelLoading] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<string>(
    moment().subtract(1, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(moment().format("YYYY-MM-DD"));

  const [searchParams] = useSearchParams();

  const tab = searchParams.get("tab");
  useEffect(() => {
    setLoading(true);
    if (info && tab === "paymentBills") {
      getPaymentBillsList(
        info?.id,
        startDate,
        endDate,
        info?.roles.includes("WarehouseOwner") ? "WarehouseOwner" : "Seller"
      ).then(() => {
        setLoading(false);
      });
    }
  }, [getPaymentBillsList, startDate, endDate, info, tab]);

  return (
    <>
      <div className={styles.dates}>
        <div>
          <div>Период изменений</div>

          <div>
            от:
            {
              <input
                type="date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            }
          </div>

          <div>
            до:
            {
              <input
                type="date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            }
          </div>
        </div>
      </div>
      {loading ? (
        <div style={{ width: "fit-content", margin: "auto" }}>
          <BeatLoader color={"#f49100"} loading={loading} size={50} />
        </div>
      ) : (
        <Table striped hover>
          <thead>
            <tr>
              <th>Номер документа</th>
              <th>Плательщик</th>
              <th>Получатель</th>
              <th>Описание</th>
              <th>цена</th>
              <th>НДС</th>
              <th>Тип счета</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {paymentBills &&
              paymentBills.map((item, index) => (
                <tr key={index}>
                  <td>{item.documentNm}</td>
                  <td>{item.payerFullName}</td>
                  <td>{item.recipientFullName}</td>
                  <td>{item.description}</td>
                  <td>{item.totalPrice}</td>
                  <td>{item.totalVat}</td>
                  <td>{item.billType}</td>
                  <td className={styles.packageLabel}>
                    <div
                      style={{
                        cursor: labelLoading ? "progress" : "pointer",
                      }}
                      onClick={() => {
                        setLabelLoading(true);

                        downloadBill(item.documentNm).then((i) => {
                          if (!i) {
                            toast.error("Этикетка еще не готова.");
                          }
                          setLabelLoading(false);
                        });
                      }}
                      className={styles.packageLabelA}
                    >
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Скачать</Tooltip>}
                      >
                        <div>
                          <HiDocumentDownload size={38} />
                        </div>
                      </OverlayTrigger>
                    </div>
                  </td>
                  <td>
                    <Button
                      className={styles.payTbButton}
                      disabled={
                        (info?.id === item.payerID && item.payerSign) ||
                        (info?.id === item.recipientID &&
                          item.recipientFullName)
                          ? true
                          : false
                      }
                      onClick={() => {
                        if (info?.id) {
                          signPaymentBill(info?.id, [item.documentNm]);
                        }
                      }}
                    >
                      {(info?.id === item.payerID && item.payerSign) ||
                      (info?.id === item.recipientID && item.recipientFullName)
                        ? "Подписано"
                        : "Подписать"}
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </>
  );
});
export default PaymentBills;
