import Modal from "../Modal";
import styles from "./DeleteModal.module.scss";
import Button from "../Button";
import React from "react";

interface Props {
  text: string;
  children?: any;
  close: any;
  okFunc: any;
  okFuncDisabled?: boolean;
}
const DeleteModal: React.FC<Props> = ({
  text,
  children,
  close,
  okFunc,
  okFuncDisabled = false,
}) => {
  return (
    <Modal
      isOpen={true}
      onClose={() => {
        close();
      }}
      maxWidth={"350px"}
    >
      <div className={styles.delete}>
        <p className={styles.text}> {text}</p>
        {children}
        <div className={styles.btns}>
          <Button
            text="Нет"
            fullWidth
            color="secondary"
            onClick={() => close()}
          />
          <Button
            text="Да"
            fullWidth
            onClick={() => okFunc()}
            disabled={okFuncDisabled}
          />
        </div>
      </div>
    </Modal>
  );
};
export default DeleteModal;
