import { Tab, Tabs } from "react-bootstrap";
import React, { useContext } from "react";
import styles from "./Invoices.module.scss";
import { observer } from "mobx-react-lite";
import PaymentBills from "./PaymentBills";
import Torg12 from "./Torg12";
import { RootStoreContext } from "stores/RootStore";
import ReturnInvoices from "./ReturnInvoices";
import { useSearchParams } from "react-router-dom";

const InvoicesPage: React.FC = observer(() => {
  const {
    userStore: { info },
  } = useContext(RootStoreContext);

  const [searchParams, setSearchParams] = useSearchParams();

  const newParams = new URLSearchParams(searchParams);

  const tab = searchParams.get("tab");
  const changeTab = (name: string | null) => {
    if (name) {
      newParams.set("tab", name);
      setSearchParams(newParams);
    }
  };

  return (
    <div className={styles.root}>
      <p className={styles.title}>Накладные</p>
      <div className={styles.wrapper}>
        <Tabs
          defaultActiveKey={tab || ""}
          id="uncontrolled-tab-example"
          className="mb-3"
          onSelect={(e) => changeTab(e)}
        >
          {info?.roles.includes("WarehouseOwner") ||
          info?.roles.includes("SuperAdmin") ? (
            <Tab eventKey="torg12_Roomseller" title="Торг 12 Roomseller">
              <Torg12 type={0} />
            </Tab>
          ) : (
            ""
          )}
          {info?.roles.includes("Seller") ||
          info?.roles.includes("SuperAdmin") ? (
            <Tab eventKey="torg12_other" title="Торг 12 другие">
              <Torg12 type={1} />
            </Tab>
          ) : (
            ""
          )}
          <Tab eventKey="paymentBills" title="Оплата счетов">
            <PaymentBills />
          </Tab>
          <Tab eventKey="returnInvoices" title="Возвраты">
            <ReturnInvoices />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
});
export default InvoicesPage;
