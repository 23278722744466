import React, { SetStateAction, useContext, useState } from "react";
import styles from "./InputField.module.scss";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "stores/RootStore";
import DeleteModal from "components/DeleteModal";
import Button from "components/Button";
interface Props {
  productId: string;
}
const InputField: React.FC<Props> = observer(({ productId }) => {
  const {
    warehouseStore: { addProductChat },
  } = useContext(RootStoreContext);
  const [message, setMessage] = useState<string>("");
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const handleChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setMessage(event.target.value);
  };

  const handlerClick = () => {
    setDeleteModalOpen(true);
  };
  const closeClick = () => {
    setDeleteModalOpen(false);
    setMessage("");
  };
  return (
    <div className={styles.sendQuestionContainer}>
      <textarea
        value={message}
        onChange={handleChange}
        placeholder="Введите текст здесь"
      ></textarea>
      <Button
        className={styles.sendQuestionBtn}
        onClick={() => handlerClick()}
        disabled={!message}
      >
        Отправить вопрос
      </Button>
      {deleteModalOpen && (
        <DeleteModal
          text={"Вы уверены, что хотите отправить вопрос?"}
          close={() => closeClick()}
          okFunc={() => {
            addProductChat(productId, message);
            setDeleteModalOpen(false);
            setMessage("");
          }}
        />
      )}
    </div>
  );
});
export default InputField;
